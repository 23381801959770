.page_heading_container h2{
    text-align: center;
}

.section_heading h2{
    text-align: center;
}
.left_right_section_1, .left_right_section_2{
    display: flex;
    gap: 1rem;
}

.left_image img{
    width: 30rem;
    border-radius: 1.5rem;
}
.left_right_section_1, .left_right_section_2 {
    margin-top: 1rem;
}


/* MEDIA QUERY */
/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {
    .left_right_section_1{
        flex-direction: column;
    }
    .left_right_section_2{
        flex-direction: column-reverse;
    }
    .left_image img{
        width: 100%;
    }
}