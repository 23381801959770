.gallery_section_container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:  1rem;
}

.image_container {
    max-width: 20rem;
    max-height: 18rem;
    border:  0.3rem solid #F29200;
    border-radius: 0.8rem;
}
.image_container img{
    width: 100%;
    border-radius: 0.5rem;
}
