.page_main_container {
    background-color: #45526E;
    color: #fff;
    padding: 1rem;
}

.footer_column_container {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-around;
    gap: 1rem;
}

.col_container {
    max-width: 20rem;
}

.col_container a {
    text-decoration: none;
}

.col_container a:hover {
    color: #f29200;
}

.first_column {
    text-align: center;
}

.first_column img {
    width: 7rem;
}

.first_column p {
    text-align: justify;
}

.second_column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.row_contents {
    display: flex;
    gap: 1rem;
}

.row_contents img {
    height: 26px;
    width: 26px;
}

.third_column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* MEDIA QUERY */
/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {
    .footer_column_container {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .col_container {
        max-width: 100%;
    }
}